.root {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}

.logo {
  width: 150px;
}

.title {
  font-size: 1.2rem;
  margin: 0;
}

.locationTitle {
  margin-bottom: 16px;
}

.inputContainer {
  margin-bottom: 32px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 36px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.name {
  font-weight: normal;
  font-size: 1.2rem;
  margin: 0;
}

.callButton {
  padding: 8px 12px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
