.searchContainer {
  position: relative;
  width: 350px;
  margin: 0 auto;
  border: 2px solid #ccc;
  border-radius: 10px; /* Reduced border-radius */
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  height: 55px;
}

.searchInput {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 20px;
  background-color: #fafafa;
  color: #3b94f0;
}

.searchInput::placeholder {
  color: #ccc;
}

.searchIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
